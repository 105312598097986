import Navbar from './components/Navbar';
import Home from './components/Home';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './index.css';
import { SpeedInsights } from '@vercel/speed-insights/react';
import './hr.css';

function App() {
  return (
    <main className="bg-[#121212] w-screen">
      <SpeedInsights />

      <Navbar />
      <Home />
      <div className="ty">
        <hr></hr>
      </div>
      <Skills />
      <div className="ty tm">
        <hr></hr>
      </div>
      <Projects />
      <Contact />
      <footer>
        <div className="fline">
          <hr></hr>
        </div>
        <Footer />
      </footer>
    </main>
  );
}

export default App;
