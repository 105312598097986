import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai';
import { SiFrontendmentor, SiTwitter } from 'react-icons/si';
import './footer.css';
import img from '../assets/Braye Logo (White).png';

const Footer = () => {
  return (
    <footer className="footer" role="contentinfo">
      <div>
        <h1>
          <img src={img} height={60} width={120} alt="bryan logo" />
        </h1>
        <ul className="foot-icon">
          <li>
            <a href="https://github.com/obraye1"  aria-label="my github repo">
              <AiFillGithub size={44} />
            </a>
          
          </li>
          <li>
            <a href="https://www.frontendmentor.io/profile/obraye1" aria-label="frontend mentor page">
              <SiFrontendmentor size={44} />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/braye" aria-label="my linkedin profile">
              <AiFillLinkedin size={44}  />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/Templebryan" aria-label="my X.com page">
              <SiTwitter size={44}  />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
