import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai';
import { SiFrontendmentor, SiTwitter } from 'react-icons/si';
import './navbar.css';
import img from '../assets/Braye Logo (White).png';

const Navbar = () => {
  return (
    <header>
      <nav className="navbar " role="navigation">
        <div className="max-w-full pl-5 py-4">
          <img src={img} height={100} width={120} alt="bryan's logo" />
        </div>
        <ul className="nav-icon">
          <li>
            <a href="https://github.com/obraye1" aria-label="my github repo">
              <AiFillGithub size={30} className="tr" />
            </a>
          </li>
          <li>
            <a href="https://www.frontendmentor.io/profile/obraye1" aria-label="frontend mentor page">
              <SiFrontendmentor size={30} />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/braye">
              <AiFillLinkedin size={30} aria-label="my linkedin profile" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/Templebryan">
              <SiTwitter size={30} aria-label="my X.com page"/>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;

/* <figure class="md:flex bg-slate-100 rounded-xl p-8 md:p-0 dark:bg-slate-800">
  <img class="w-24 h-24 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto" src="/sarah-dayan.jpg" alt="" width="384" height="512">
  <div class="pt-6 md:p-8 text-center md:text-left space-y-4">
    <blockquote>
      <p class="text-lg font-medium">
        “Tailwind CSS is the only framework that I've seen scale
        on large teams. It’s easy to customize, adapts to any design,
        and the build size is tiny.”
      </p>
    </blockquote>
    <figcaption class="font-medium">
      <div class="text-sky-500 dark:text-sky-400">
        Sarah Dayan
      </div>
      <div class="text-slate-700 dark:text-slate-500">
        Staff Engineer, Algolia
      </div>
    </figcaption>
  </div>
</figure> */
