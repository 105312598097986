import React, { useRef } from 'react';
import './home.css';
import { projects } from '../data/skillsData';

const Projects = () => {
  const overlayRefs = useRef([]);

  const handleFocus = (index) => {
    overlayRefs.current[index].style.opacity = '1';
    overlayRefs.current[index].style.visibility = 'visible';
  };

  const handleBlur = (index) => {
    overlayRefs.current[index].style.opacity = '0';
    overlayRefs.current[index].style.visibility = 'hidden';
  };

  return (
    <div className="w-full justify-center items-center p-4 font-sans">
      <div className="flex max-w-[1240px] justify-between items-center p-4 mx-auto">
        <h2 className="text-xl sm:text-4xl font-semibold cursor-default">
          Projects
        </h2>
        <button
          className="border-b hover:border-b-[#c5501e] focus:outline-none focus:underline"
          tabIndex="0"
        >
          <a
            href="https://medium.com/@obrayecom"
            className="cursor-pointer text-xl hover:text-[#c5501e] focus:text-[#c5501e] focus:outline-none"
          >
            medium blogs
          </a>
        </button>
      </div>
      <div className="grid md:grid-cols-2 gap-8 items-center max-w-[1240px] p-4 mx-auto">
        {projects.map((item, index) => (
          <div key={item.id} className="">
            <div
              className="relative group"
              onMouseEnter={() => handleFocus(index)}
              onMouseLeave={() => handleBlur(index)}
              tabIndex="0"
            >
              <img
                src={item.img}
                alt={`Homepage snapshot of ${item.title}`}
                className="h-[253px] w-full md:h-[380px] object-cover rounded-3xl"
                loading="lazy"
              />
              <div
                className="absolute inset-0 bg-black/75 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex justify-center items-center gap-4 rounded-3xl"
                role="region"
                aria-label="Project links"
                ref={(el) => (overlayRefs.current[index] = el)}
                style={{ visibility: 'hidden' }}
              >
                <a
                  href={item.web}
                  className="btn font-medium text-[#845ec2] focus:outline-none focus:underline"
                  role="button"
                  tabIndex="0"
                >
                  VIEW PROJECT
                </a>
                <a
                  href={item.code}
                  className="btn font-medium text-[#845ec2] focus:outline-none focus:underline"
                  role="button"
                  tabIndex="0"
                >
                  VIEW CODE
                </a>
              </div>
            </div>
            <div className="font-bold py-4 cursor-default">
              <h3 className="sm:text-3xl text-md font-sans">{item.title}</h3>
              <ul className="flex gap-2  ">
                <li className="text-[#c4b8d7]">{item.tech}</li>
                <li className="text-[#c4b8d7]">{item.styling}</li>
                <li className="text-[#c4b8d7]">{item.framework}</li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
