import './home.css';
import Changer from './Changer';

const Home = () => {
  return (
    <main className="mainPages cursor-default">
      <div className="text-container">
        <div className="txtHp">
          <div className="home-h text-4xl font-bold sm:text-4xl md:text-7xl 4xl:text-8xl">
            <h1 className="  py-4">
              Nice to meet you! I'm
              <span className="underLine "> Bryan Temple.</span>
            </h1>
          </div>
          <div className="text-para max-w-4xl">
            <p className="text-2xl sm:text-2xl md:text-2xl 4xl:text-3xl sm:leading-6 py-4 text-center sm:text-left">
              Based in Nigeria. I'm a frontend designer passsionate about
              building accessible web applications, a minimalist and microscopic
              eye for detail, and an avid learner always seeking new knowledge-
              my goal is to create digital experience that are inclusive,
              user-focused, WCAG-triple-A enthusiast.
            </p>
            <div className="align-center flex sm:justify-normal justify-center">
              <a
                href="#contact"
                className="btn animate-pulse text-center  font-semibold uppercase border-b "
                role="button"
              >
                contact me
              </a>
              {/* <Changer /> */}
            </div>
          </div>
        </div>
      </div>
      <div
        type="image"
        className="houseImg rounded-b-xl "
        alt="a black and white image-of bryan, west african black male, on glasses, short breads and looking sidewards not facing the camera "
      ></div>
    </main>
  );
};

export default Home;
