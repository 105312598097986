import { useState, useRef } from 'react';
import './contact.css';
import emailjs from '@emailjs/browser';
import { RiErrorWarningLine } from 'react-icons/ri';

const Contact = () => {
  const form = useRef();
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailBlur = () => {
    if (!email) {
      setError('Email is required.');
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Invalid email format.');
    } else {
      setError('');
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_65rbfyr',
        'template_ogr44a3',
        form.current,
        'Voh9y9ywGUclzTnZe'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="contact max-width-full" id="contact">
      <div className="contact-hold">
        <div className="contactHp">
          <h2 className="font-bold">Contact</h2>
          <p>
            I would love to hear about your project and how I could help. Please
            fill in the form, and I’ll get back to you as soon as possible.
          </p>
        </div>
        <div className="form">
          <form className="form-con" ref={form} onSubmit={sendEmail}>
            <input
              className="focus:outline-none focus:valid:border-green-500 "
              type="text"
              name="name"
              id="name"
              autoComplete="new-password"
              placeholder="NAME"
              required
            />
            <div className="flex items-center justify-center relative">
              <input
                className={`focus:outline-none ${
                  error ? 'focus:invalid:border-red-600 text-red-400' : ''
                } valid:border-green-500 w-full` }
                type="email"
                name="email"
                id="email"
                placeholder="EMAIL"
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                required
                autoComplete="new-password"
              />

              {error && (
                <span className="absolute top-0 right-0 py-8 pr-2">
                  <RiErrorWarningLine className="fill-red-400 h-6 w-6" />
                </span>
              )}
              <span className="text-red-400 text-xs absolute top-0 right-0 py-[90px]">
                {error}
              </span>
            </div>

            <textarea
              id=""
              cols="14"
              rows="5"
              placeholder="YOUR MEASSAGE"
              name="message"
            ></textarea>
            <div className="button">
              <button className="btn" type="submit">
                SEND MESSAGE
              </button>
            </div>
          </form>
        </div>
        <style jsx>{`
          input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px transparent inset;
          }
        `}</style>
      </div>
    </div>
  );
};
export default Contact;
