import img1 from '../assets/project44.png';
import img2 from '../assets/project55.png';
import img3 from '../assets/project3.png';
import img4 from '../assets/project4.png';
import img5 from '../assets/project66.png';
import img6 from '../assets/project77.png';

// export const data = [
//   { id: 0, skill: 'HTML', Experience: '5 Years Experience' },
//   { id: 1, skill: 'UX', Experience: '4 Years Experience' },
//   { id: 3, skill: 'Accesibility', Experience: '1+ Year Experience' },
//   { id: 5, skill: 'Inclusive Design', Experience: '2 Years Experience' },
//   { id: 4, skill: 'ReactJS', Experience: '4 Years Experience' },
//   { id: 2, skill: 'NextJs', Experience: '2 Years Experience' },
// ];

export const projects = [
  {
    id: 1,
    img: img1,
    title: 'Responsive Dashboard',
    tech: 'HTML',
    styling: 'CSS',
    framework: 'nextjs',
    web: 'https://dashboad-kohl.vercel.app/',
    code: 'https://github.com/obraye1/dashboad',
  },
  {
    id: 2,
    img: img2,
    title: 'Weez-kitchen',
    tech: 'HTML',
    styling: 'TailWindCSS',
    framework: 'nextjs',
    web: 'https://weezkitchen.vercel.app/',
    code: 'https://github.com/obraye1/weezkitchen',
  },
  {
    id: 3,
    img: img3,
    title: 'Weather forcast',
    tech: 'TYPESCRIPT',
    styling: 'Tailwind',
    framework: 'ReactJS',
    web: 'https://cityweatherapp.vercel.app',
    code: 'https://github.com/obraye1/ToDo',
  },
  {
    id: 4,
    img: img4,
    title: 'Contribution Project',
    tech: ' Vanity',
    styling: 'Tailwind',
    framework: 'NextJS',
    web: 'https://lawgrid.vercel.app/',
    code: '/',
  },
  {
    id: 5,
    img: img5,
    title: 'Group Assessment Project',
    tech: 'HTML',
    styling: 'TailwindCSS',
    framework: 'ReactJS',
    web: 'https://fam-health.vercel.app',
    code: 'https://github.com/obraye1/famHealth',
  },
  {
    id: 6,
    img: img6,
    title: 'Flight Data Rendering',
    tech: 'HTML',
    styling: 'TailwindCSS',
    framework: 'Reactjs',
    web: 'https://britfli.vercel.app/',
    code: 'https://github.com/obraye1/flightReactJS',
  },
];
const experienceStartDates = {
  HTML: new Date(2018, 8, 1), // January 1, 2018
  UX: new Date(2021, 0, 1), // January 1, 2019
  Accesibility: new Date(2023, 0, 1), // January 1, 2022
  Inclusivity: new Date(2014, 0, 1), // January 1, 2021
  ReactJS: new Date(2021, 0, 1), // January 1, 2019
  NextJs: new Date(2021, 0, 1), // January 1, 2021
};

export const data = [
  {
    id: 0,
    skill: 'HTML',
    Experience: getYearsExperience(experienceStartDates.HTML),
  },
  {
    id: 1,
    skill: 'UX',
    Experience: getYearsExperience(experienceStartDates.UX),
  },
  {
    id: 3,
    skill: 'Accesibility',
    Experience: getYearsExperience(experienceStartDates.Accesibility),
  },
  {
    id: 5,
    skill: 'Inclusivity',
    Experience: getYearsExperience(experienceStartDates.Inclusivity),
  },
  {
    id: 4,
    skill: 'ReactJS',
    Experience: getYearsExperience(experienceStartDates.ReactJS),
  },
  {
    id: 2,
    skill: 'NextJs',
    Experience: getYearsExperience(experienceStartDates.NextJs),
  },
];

function getYearsExperience(startDate) {
  const currentDate = new Date();
  const yearsDifference = currentDate.getFullYear() - startDate.getFullYear();
  const monthsDifference = currentDate.getMonth() - startDate.getMonth();

  if (monthsDifference < 0 || (monthsDifference === 0 && currentDate.getDate() < startDate.getDate())) {
    return `${yearsDifference - 1} Years Experience`;
  } else {
    return `${yearsDifference} Years Experience`;
  }
}