import './skills.css';
import { data } from '../data/skillsData';

const Skills = () => {
  return (
    <div
      className="gridContainer py-[3em]"
      aria-label="skill gained and years of experince"
    >
      {data.map((key) => (
        <div key={key.id} className="cursor-default font-sans">
          <div className="skills-h ">
            <h2>{key.skill}</h2>
          </div>
          <div className="skills-para ">
            <p className="text-[#c4b8d7]">{key.Experience}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Skills;
